import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Form, Input } from 'antd';

import logoImg from '../../images/logo_main.jpg';

import styles from './login.less';

const FormItem = Form.Item;

const Login = (props) => {
	const { loading } = props;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	function onLogin() {
		props.onSubmit({
			email,
			password
		});
	}
	return (
		<Fragment>
			<div className={styles.form}>
				<div className={styles.logo}>
					<img alt="logo" src={logoImg} />
				</div>
				<form>
					<FormItem hasFeedback>
						{(
							<Input
								type="email"
								onChange={(ev) => {
									setEmail(ev.target.value);
								}}
								onPressEnter={onLogin}
								placeholder={'Email'}
							/>
						)}
					</FormItem>
					<FormItem hasFeedback>
						{(
							<Input
								type="password"
								onPressEnter={onLogin}
								onChange={(ev) => {
									setPassword(ev.target.value);
								}}
								placeholder={'Password'}
							/>
						)}
					</FormItem>
					<Row>
						<Button
							type="primary"
							onClick={onLogin}
							loading={loading}
						>
							Sign in
						</Button>
					</Row>
				</form>
			</div>
		</Fragment>
	);
};

Login.propTypes = {
	form: PropTypes.object,
	dispatch: PropTypes.func,
	loading: PropTypes.object
};

export default Login;
