import React, { useState } from 'react';
import LoginComponent from '../components/login';
import { message } from 'antd';
import { apiLogin } from '../api/index';

export default function LoginContainer() {
	const [loading, setLoading] = useState(false);

	async function onLoginSubmit(payload) {
		setLoading(true);
		const request = await apiLogin({ payload }).catch((error) => {
			setLoading(false);
			message.error('Wrong email or password!');
		});

		if (request) {
			setLoading(false);
			window.location.href = '/';
		}
	}

	return (<LoginComponent
		loading={loading}
		onSubmit={onLoginSubmit}
	/>);
}
