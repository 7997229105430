import 'whatwg-fetch';

const apiRequest = async (url, reqOpts) => {
	let response = await fetch(url, reqOpts);

	if (response.ok) return response.json();

	response = await response.json();

	throw response;
};

const getDefaultReqOpts = () => {
	return {
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache-control': 'no-cache',
			'Pragma': 'no-cache'
		}
	};
};

const apiGet = async (url) => {
	const reqOpts = getDefaultReqOpts();
	const req = await apiRequest(url, reqOpts);
	return req;
};

const apiPut = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'PUT';
	reqOpts.body = JSON.stringify(options.payload);

	const req = await apiRequest(url, reqOpts);

	return req;
};

const apiPost = async (url, options) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'POST';
	reqOpts.body = JSON.stringify(options.payload);

	const req = await apiRequest(url, reqOpts);

	return req;
};

const apiDelete = async (url) => {
	const reqOpts = getDefaultReqOpts();

	reqOpts.method = 'DELETE';
	const req = await apiRequest(url, reqOpts);

	return req;
};


// SELF
export const apiGetUserSelf = async (options) => {
	const req = await apiGet('/api/self', options);
	return req;
};

export const apiPutSettings = async (options) => {
	const req = await apiPut('/api/self/settings', options);
	return req;
};

// USERS
export const apiPostUser = async (options) => {
	const req = await apiPost('/api/users', options);
	return req;
};

export const apiPutUser = async (id, options) => {
	const req = await apiPut(`/api/user/${id}`, options);
	return req;
};

export const apiUserSave = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutUser(options.payload.id, options);
		return req;
	}

	req = await apiPostUser(options);
	return req;
};

export const apiDeleteUser = async (id, options) => {
	const req = await apiDelete(`/api/user/${id}`, options);
	return req;
};

export const apiGetUsers = async (options) => {
	const req = await apiGet('/api/users', options);
	return req;
};

// CUSTOMERS
export const apiPostCustomer = async (options) => {
	const req = await apiPost('/api/customers', options);
	return req;
};

export const apiPutCustomer = async (id, options) => {
	const req = await apiPut(`/api/customer/${id}`, options);
	return req;
};

export const apiCustomerSave = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutCustomer(options.payload.id, options);
		return req;
	}

	req = await apiPostCustomer(options);
	return req;
};

export const apiDeleteCustomer = async (id, options) => {
	const req = await apiDelete(`/api/customer/${id}`, options);
	return req;
};

export const apiGetCustomers = async (options) => {
	const req = await apiGet('/api/customers', options);
	return req;
};

// PRODUCTS
export const apiPostProduct = async (options) => {
	const req = await apiPost('/api/products', options);
	return req;
};

export const apiPutProduct = async (id, options) => {
	const req = await apiPut(`/api/product/${id}`, options);
	return req;
};

export const apiProductSave = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutProduct(options.payload.id, options);
		return req;
	}

	req = await apiPostProduct(options);
	return req;
};

export const apiDeleteProduct = async (id, options) => {
	const req = await apiDelete(`/api/product/${id}`, options);
	return req;
};

export const apiGetProducts = async (options) => {
	const req = await apiGet('/api/products', options);
	return req;
};

// JOBS
export const apiPostJob = async (options) => {
	const req = await apiPost('/api/jobs', options);
	return req;
};

export const apiPutJob = async (id, options) => {
	const req = await apiPut(`/api/job/${id}`, options);
	return req;
};

export const apiJobSave = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutJob(options.payload.id, options);
		return req;
	}

	req = await apiPostJob(options);
	return req;
};

export const apiDeleteJob = async (id, options) => {
	const req = await apiDelete(`/api/job/${id}`, options);
	return req;
};

export const apiGetJobs = async (query, options) => {
	const req = await apiGet(`/api/jobs${query || ''}`, options);
	return req;
};

export const apiGetJob = async (id, options) => {
	const req = await apiGet(`/api/job/${id}`, options);
	return req;
};

// LEADS

export const apiPostLead = async (options) => {
	const req = await apiPost('/api/leads', options);
	return req;
};

export const apiPutLead = async (id, options) => {
	const req = await apiPut(`/api/lead/${id}`, options);
	return req;
};

export const apiLeadSave = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutLead(options.payload.id, options);
		return req;
	}

	req = await apiPostLead(options);
	return req;
};

export const apiDeleteLead = async (id, options) => {
	const req = await apiDelete(`/api/lead/${id}`, options);
	return req;
};

export const apiGetLeads = async (query, options) => {
	const req = await apiGet(`/api/leads${query || ''}`, options);
	return req;
};

// LINE CHECKS
export const apiPostLineCheck = async (jobId, options) => {
	const req = await apiPost(`/api/job/${jobId}/lines`, options);
	return req;
};

export const apiPutLineCheck = async (jobId, id, options) => {
	const req = await apiPut(`/api/job/${jobId}/line/${id}`, options);
	return req;
};

export const apiLineCheckSave = async (jobId, options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutLineCheck(jobId, options.payload.id, options);
		return req;
	}

	req = await apiPostLineCheck(jobId, options);
	return req;
};

export const apiDeleteLineCheck = async (jobId, id, options) => {
	const req = await apiDelete(`/api/job/${jobId}/line/${id}`, options);
	return req;
};

export const apiGetLineChecks = async (jobId, options) => {
	const req = await apiGet(`/api/job/${jobId}/lines`, options);
	return req;
};

// FILES
export const apiPutFile = async (id, options) => {
	const req = await apiPut(`/api/file/${id}`, options);
	return req;
};

export const apiDeleteFile = async (id, options) => {
	const req = await apiDelete(`/api/file/${id}`, options);
	return req;
};

export const apiGetFiles = async (query, options) => {
	const req = await apiGet(`/api/files/${query}`, options);
	return req;
};


export const apiGetReports = (query, options) => apiGet(`/api/reports${query}`, options);
export const apiGetReportsSelf = options => apiGet('/api/reports/self', options);
export const apiDeleteReport = (id, options) => apiDelete(`/api/report/${id}`, options);

export const apiBulkDeleteReports = options => apiDelete(`/api/reports?ids=${encodeURIComponent(JSON.stringify(options.payload.ids))}`, options);
export const apiBulkUpdateReports = options => apiPut('/api/reports', options);

// INVOICES
export const apiGetInvoices = async (query, options) => {
	const req = await apiGet(`/api/invoices${query}`, options);
	return req;
};

export const apiGetInvoice = async (id, options) => {
	const req = await apiGet(`/api/invoice/${id}`, options);
	return req;
};

export const apiPostInvoice = async (options) => {
	const req = await apiPost('/api/invoice', options);
	return req;
};

export const apiPutInvoice = async (id, options) => {
	const req = await apiPut(`/api/invoice/${id}`, options);
	return req;
};

export const apiSaveInvoice = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutInvoice(options.payload.id, options);
		return req;
	}

	req = await apiPostInvoice(options);
	return req;
};

export const apiExportInvoice = async (id, options) => {
	const req = await apiGet(`/api/invoice/${id}/export`, options);
	return req;
};

export const apiDeleteInvoice = async (id, options) => {
	const req = await apiDelete(`/api/invoice/${id}`, options);
	return req;
};

export const apiGetNextInvoiceNr = async () => {
	const req = await apiGet('/api/invoice/nextInvoiceNr');
	return req;
};

// CMR
export const apiGetCMRs = async (query, options) => {
	const req = await apiGet(`/api/cmrs${query}`, options);
	return req;
};

export const apiGetCMR = async (id, options) => {
	const req = await apiGet(`/api/cmr/${id}`, options);
	return req;
};

export const apiPostCMR = async (options) => {
	const req = await apiPost('/api/cmr', options);
	return req;
};

export const apiPutCMR = async (id, options) => {
	const req = await apiPut(`/api/cmr/${id}`, options);
	return req;
};

export const apiSaveCMR = async (options) => {
	let req;

	if (options.payload.id) {
		req = await apiPutCMR(options.payload.id, options);
		return req;
	}

	req = await apiPostCMR(options);
	return req;
};

export const apiExportCMR = async (id, options) => {
	const req = await apiGet(`/api/cmr/${id}/export`, options);
	return req;
};

export const apiDeleteCMR = async (id, options) => {
	const req = await apiDelete(`/api/cmr/${id}`, options);
	return req;
};

export const apiGetNextCMRNr = async () => {
	const req = await apiGet('/api/cmr/nextCMRNr');
	return req;
};

export const apiExportEstimates = async (query, options) => {
	const req = await apiGet(`/api/reports/estimates/export${query}`, options);
	return req;
};

export const apiExportActuals = async (query, options) => {
	const req = await apiGet(`/api/reports/actuals/export${query}`, options);
	return req;
};

// OTHER

export const apiLogin = options => apiPost('/auth/login', options);
